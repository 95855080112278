@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'mundial-thin';
  src: url('./assets/fonts/mundial-thin.woff2') format('woff2'),
  url('./assets/fonts/mundial-thin.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'mundial-light';
  src: url('./assets/fonts/mundial-light.woff2') format('woff2'),
  url('./assets/fonts/mundial-light.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'mundial-regular';
  src: url('./assets/fonts/mundial-regular.woff2') format('woff2'),
  url('./assets/fonts/mundial-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'mundial-bold';
  src: url('./assets/fonts/mundial-bold.woff2') format('woff2'),
  url('./assets/fonts/mundial-bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
